import { render, staticRenderFns } from "./15zhihuichengguan.vue?vue&type=template&id=47aea688&scoped=true&"
import script from "./15zhihuichengguan.vue?vue&type=script&lang=js&"
export * from "./15zhihuichengguan.vue?vue&type=script&lang=js&"
import style0 from "./15zhihuichengguan.vue?vue&type=style&index=0&id=47aea688&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47aea688",
  null
  
)

export default component.exports