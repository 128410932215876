<template>
	<div class="page">
		<Head :headTitle="headTitle" />
		<div class="container">
			<div class="con-l">
				<div class="mod mod1">
					<div class="s-pub-tt flex">
						<span class="bt">县城情况</span>
					</div>
					<ul class="mod1-list flex-1">
						<li>
							<div class="tit">面积</div>
							<div class="txt">
								<span>2,538</span>km²
							</div>
						</li>
						<li>
							<div class="tit">报人口总数</div>
							<div class="txt">
								<span>19</span>万人
							</div>
						</li>
						<li>
							<div class="tit">行政村</div>
							<div class="txt">
								<span>250</span>个
							</div>
						</li>
						<li>
							<div class="tit">街道数量</div>
							<div class="txt">
								<span>10</span>个
							</div>
						</li>
						<li>
							<div class="tit">镇数量</div>
							<div class="txt">
								<span>6</span>个
							</div>
						</li>
						<li>
							<div class="tit">乡数量</div>
							<div class="txt">
								<span>4</span>个
							</div>
						</li>
					</ul>
				</div>
				<div class="mod mod2">
					<div class="s-pub-tt flex">
						<span class="bt">综合执法</span>
					</div>
					<div class="chart flex-11" ref="chart1"></div>
				</div>
				<div class="mod mod3">
					<div class="s-pub-tt flex">
						<span class="bt">区域案情</span>
					</div>
					<div class="chart flex-11" ref="chart2"></div>
				</div>
			</div>
			<div class="con-c flex flex-v relative">
				<ul class="cen-list">
					<li>
						<h6 class="title">本月未结案<em>(件)</em></h6>
						<div class="txt">138</div>
					</li>
					<li>
						<h6 class="title">本月结案<em>(件)</em></h6>
						<div class="txt">553</div>
					</li>
					<li>
						<h6 class="title">年度新增案<em>(件)</em></h6>
						<div class="txt">7,610</div>
					</li>
				</ul>
				<div class="cen-map">
					<div class="point point1">
						<span class="tag">垃圾堆放：农贸菜市</span>
						<img src="../assets/images/cg_01.png"  class="icon" alt="">
					</div>
					<div class="point point2">
						<span class="tag">垃圾堆放：农贸菜市</span>
						<img src="../assets/images/cg_01.png"  class="icon" alt="">
					</div>
					<div class="point point3">
						<span class="tag">垃圾堆放：农贸菜市</span>
						<img src="../assets/images/cg_01.png"  class="icon" alt="">
					</div>
					
					<div class="point point4">
						<span class="tag">垃圾堆放：农贸菜市</span>
						<img src="../assets/images/cg_01.png"  class="icon" alt="">
					</div>
					
					
					
					<div class="item item1">
						<div class="top">
							<img src="../assets/images/wlv_09.webp" class="hdpic" alt="">
							<div class="info">
								<div class="flex flex-align-center">
									<h6 class="name">张山</h6>
									<span class="tag">应急组员</span>
								</div>
								<div class="tel">139 8887 6281</div>
							</div>
						</div>
						<div class="txt">
							施工队进场，对道路的一侧进行开挖，要在这条路上埋设污水管道和雨水管道。道路上全是泥浆水，来往的电瓶车很容易滑倒。
						</div>
						<div class="pics">
							<img src="../assets/images/wlv_10.gif" class="pic" alt="">
							<img src="../assets/images/wlv_11.gif" class="pic" alt="">
							<img src="../assets/images/wlv_12.gif" class="pic" alt="">
						</div>
						<div class="dz">
							古州北路29号
						</div>
					</div>
				</div>
			</div>
			<div class="con-r flex flex-v">
				<div class="mod mod4">
					<div class="s-pub-tt flex">
						<span class="bt">案件高发类型</span>
					</div>
					<div class="chart flex-11" ref="chart3"></div>
				</div>
				<div class="mod mod5">
					<div class="s-pub-tt flex">
						<span class="bt">今日案件来源</span>
					</div>
					<div class="chart flex-11" ref="chart4"></div>
				</div>
				<div class="mod mod6">
					<div class="s-pub-tt flex">
						<span class="bt">文明城市</span>
					</div>
					<ul class="mod6-list flex-1">
						<li class="item" v-for="(item,index) in monitorList" :key="index">
							<img :src="item.img" class="img img_full" alt="">
							<h6 class="title">{{item.title}}</h6>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Head from '@/components/head_sm.vue'
	let Echarts = require('echarts/lib/echarts');
	import {
		loopShowTooltip
	} from "../assets/js/tooltip-auto-show-vue.js";
	export default {
		data() {
			return {
				headTitle: '龙胜县智慧城管展示平台',
				monitorList: [{
					img: require('../assets/images/img_05.gif'),
					title: ' 管网施工：人民路段'
				}, {
					img: require('../assets/images/img_06.gif'),
					title: '占道经营：码头农贸市场'
				}, {
					img: require('../assets/images/img_07.gif'),
					title: '垃圾堆放：宏坤农贸市场'
				}, {
					img: require('../assets/images/img_05.gif'),
					title: '堵塞情况：自然村路段'
				}],
				
				chart1: null,
				tootipTimer1: "",
				chart2: null,
				tootipTimer2: "",
				chart3: null,
				tootipTimer3: "",
				chart4: null,
				tootipTimer4: "",
			}
		},
		components: {
			Head

		},
		computed: {
			
		},
		created() {

		},
		mounted() {
			this.init1();
			this.init2();
			this.init3();
			this.init4();
			window.addEventListener('resize', () => {
				this.chart1.resize();
				this.chart2.resize();
				this.chart3.resize();
				this.chart4.resize();
				
			});
		},

		methods: {
			
			init1() {
				this.chart1 = Echarts.init(this.$refs.chart1);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF'
					},
					toolbox: {
						show: true
					},
					grid: {
						left: '0',
						right: nowSize(10),
						bottom: nowSize(10),
						top: nowSize(70),
						containLabel: true
					},
			
					legend: {
						top: '0',
						x: 'right',
						textStyle: {
							color: '#fff',
							fontSize: nowSize(24)
						},
						itemWidth: nowSize(22),
						itemHeight: nowSize(8),
						icon: 'rect'
					},
					xAxis: [{
						type: 'category',
						//boundaryGap: false,
						data: ['11-18','11-19','11-20','11-21','11-22','11-23'],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(24)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						//max: 60000,
						//interval: 3000,
						nameTextStyle: {
							color: "#8ea1ba",
							fontSize: nowSize(14),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(24), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
			
					series: [{
						name: '立案数量',
						type: 'line',
						data: [200.82272,223.1728,178.53824,245.49008,334.7592,267.80736],
						smooth: false,
						symbolSize: 3, //折线点的大小
						itemStyle: {
							normal: {
								color: "#02B581", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#02B581" //折线的颜色
			
								}
							}
						}
					}, {
						name: '结案数量',
						type: 'line',
						data: [245.49008,178.53824,200.85552,146.22096,223.1729,267.80736,245.49008],
						smooth: false,
						symbolSize: 3, //折线点的大小
						itemStyle: {
							normal: {
								color: "#FD3728", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#FD3728" //折线的颜色
						
								}
							}
						}
					},{
						name: '从罚告知数量',
						type: 'line',
						data: [11.15864,13.309368,8.926912,15.622096,33.47592,17.853824],
						smooth: false,
						symbolSize: 3, //折线点的大小
						itemStyle: {
							normal: {
								color: "#0060DB", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#0060DB" //折线的颜色
						
								}
							}
						}
					}]
			
				};
				this.chart1.setOption(option, true);
			
				this.tootipTimer1 && this.tootipTimer1.clearLoop();
				this.tootipTimer1 = 0;
				this.tootipTimer1 = loopShowTooltip(this.chart1, option, {});
			
			},
			
			init2() {
				this.chart2 = Echarts.init(this.$refs.chart2);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF'
					},
					toolbox: {
						show: true
					},
					grid: {
						left: '0',
						right: nowSize(10),
						bottom: nowSize(10),
						top: nowSize(70),
						containLabel: true
					},
			
					legend: {
						top: '0',
						x: 'right',
						textStyle: {
							color: '#fff',
							fontSize: nowSize(24)
						},
						itemWidth: nowSize(22),
						itemHeight: nowSize(8),
						icon: 'rect'
					},
					xAxis: [{
						type: 'category',
						//boundaryGap: false,
						data: ['街道1','街道2','街道3','街道4','街道5','街道6'],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(24)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						//max: 60000,
						//interval: 3000,
						nameTextStyle: {
							color: "#8ea1ba",
							fontSize: nowSize(14),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(24), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
			
					series: [{
						name: '案件上报数量',
						type: 'line',
						data: [200.82272,223.1728,178.53824,245.49008,334.7592,267.80736],
						smooth: false,
						symbolSize: 3, //折线点的大小
						itemStyle: {
							normal: {
								color: "#02B581", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#02B581" //折线的颜色
								
								}
							}
						}
					}, {
						name: '案件立案数量',
						type: 'line',
						data: [245.49008,178.53824,200.85552,146.22096,223.1729,267.80736,245.49008],
						smooth: false,
						symbolSize: 3, //折线点的大小
						itemStyle: {
							normal: {
								color: "#FD3728", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#FD3728" //折线的颜色
						
								}
							}
						}
					},{
						name: '案件结案数量',
						type: 'line',
						data: [11.15864,13.309368,8.926912,15.622096,33.47592,17.853824],
						smooth: false,
						symbolSize: 3, //折线点的大小
						itemStyle: {
							normal: {
								color: "#0060DB", //折线点的颜色
								borderWidth: 1,
								lineStyle: {
									color: "#0060DB" //折线的颜色
						
								}
							}
						}
					}]
			
				};
				this.chart2.setOption(option, true);
			
				this.tootipTimer2 && this.tootipTimer2.clearLoop();
				this.tootipTimer2 = 0;
				this.tootipTimer2 = loopShowTooltip(this.chart2, option, {});
			
			},
			init3() {
				this.chart3 = Echarts.init(this.$refs.chart3);
				let option = {
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF'
					},
					toolbox: {
						show: true
					},
					grid: {
						left: '0',
						right: nowSize(10),
						bottom: nowSize(10),
						top: nowSize(70),
						containLabel: true
					},
			
					legend: {
						top: '0',
						x: 'right',
						textStyle: {
							color: '#fff',
							fontSize: nowSize(24)
						},
						itemWidth: nowSize(22),
						itemHeight: nowSize(8),
						icon: 'rect'
					},
					xAxis: [{
						type: 'category',
						//boundaryGap: false,
						data: ['乱搭乱建','暴露垃圾','非法小广告','建筑物外不洁','无照经营','其他'],
						axisPointer: {
							type: ''
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff',
								fontSize: nowSize(22)
							},
							//rotate: '0',
							interval: 0
						},
						axisTick: {
							show: false
						},
						axisLine: {
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: ['#edf2f8'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						position: 'left',
						min: 0,
						//max: 60000,
						//interval: 3000,
						nameTextStyle: {
							color: "#8ea1ba",
							fontSize: nowSize(14),
							padding: [0, 0, 0, -20]
						},
						axisLabel: {
							show: true,
							textStyle: {
								color: '#fff', //更改坐标轴文字颜色
								fontSize: nowSize(24), //更改坐标轴文字大小
							},
							//formatter: '{value}%'
						},
						axisTick: {
							show: false
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#023748' //更改坐标轴颜色
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: ['#023748'],
								width: 1,
								type: 'dashed'
							}
						}
					}],
			
					series: [{
						name: '案件数量',
						type: 'bar',
						stack: 'Total',
						label: {
							show: true,
							position: 'top',
							textStyle: {
								color: '#fff',
								fontSize: nowSize(22)
							},
						},
						barWidth: nowSize(20),
						data: [15220,11415,19787,7610,3805,18264],
						itemStyle: {
							normal: {
								barBorderRadius:0,
								color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [{
									offset: 0,
									color: '#44f3ff'
								}, {
									offset: 1,
									color: '#45beff'
								}]),
							}
						}
					}]
			
				};
				this.chart3.setOption(option, true);
			
				this.tootipTimer3 && this.tootipTimer3.clearLoop();
				this.tootipTimer3 = 0;
				this.tootipTimer3 = loopShowTooltip(this.chart3, option, {});
			},


			init4() {
				this.chart4 = Echarts.init(this.$refs.chart4);
				const chartData = [
					{
						value: 102,
						name: '采集员上报',
					},
					{
						value: 22,
						name: '市民随手拍'
					},
					{
						value: 44,
						name: '美丽县城APP'
					},
					{
						value: 17,
						name: '公共举报'
					},
					{
						value: 35,
						name: '智慧城管'
					}
				];
				const sum = chartData.reduce((per, cur) => per + cur.value, 0)
				let option = {
					title: {
						text: `{a|主要来源}\n\n{b|采集员上报}`,
						top: 'center',
						textStyle: {
							rich: {
								a: {
									fontSize: nowSize(38),
									padding: [nowSize(-20), 0, 0, nowSize(160)],
									color: '#fff',
									lineHeight: 1,
									fontFamily: 'PangMenZhengDao'
								},
								b: {
									fontSize: nowSize(30),
									padding: [0, 0, 0, nowSize(165)],
									color: '#45F5FF',
									lineHeight: 1,
								 fontFamily: 'PangMenZhengDao'
								}
							}
						}
					},
					tooltip: {
						trigger: 'item',
						backgroundColor: 'rgba(0,0,0,.9)',
						textStyle: {
							fontSize: nowSize(12),
							color: '#fff'
						},
						extraCssText: 'border:1px solid #3FF5FF',
						axisPointer: {
							crossStyle: {
								color: '#fff'
							}
						},
						position(point, params, dom, rect, size) {}
					},
					grid: {
						left: '0',
						right: '0',
						bottom: '0',
						top: '0',
						containLabel: true
					},
					legend: {
						x: 'right',
						y: 'center',
						orient: 'vertial',
						icon: 'rect',
						itemWidth: nowSize(24),
						itemHeight: nowSize(24),
						textStyle: {
							color: '#fff',
							fontSize: nowSize(24),
							lineHeight: nowSize(40)
						},
						formatter: name => {
							console.log(name)
							console.log(chartData)
							const item = chartData.find(i => {
								return i.name === name
							})
							const p = ((item.value / sum) * 100).toFixed(0)
							const value = item.value
							return '{name|' + name + '}' + '{value|' + value + '件}' + '{percent|' + p + '%}' 
						},
						textStyle: {
							 rich: {
								name: {
								   fontSize: nowSize(24),
								   width: nowSize(230),
								   height: nowSize(30),
								   padding: [0, 0, 0, 5],
								   color: '#fff',
								},
								value: {
								   fontSize: nowSize(24),
								   width: nowSize(110),
								   height: nowSize(30),
								   padding: [0, 0, 0, 5],
								   color: '#fff',
								},
								percent: {
								   fontSize: nowSize(24),
								   width: nowSize(110),
								   height: nowSize(30),
								   padding: [0, 0, 0, 5],
								   color: '#fff',
								},
							 }
						  }
					},
					color: ['#00b6ff', '#03b581', '#ffc71a', '#fe4335', '#0060db'],
					series: [{
						name: '',
						type: 'pie',
						radius: ['50%', '70%'],
						center: ['25%', '48%'],
						avoidLabelOverlap: false,
						hoverAnimation: true,
						label: {
							normal: {
								show: false
							}
						},
						itemStyle: {
							borderRadius: 0,
							borderColor: '#000',
							borderWidth: 3
						},
						labelLine: {
							show: false
						},
						data:chartData
					}, {
						type: 'pie',
						name: '内层细圆环',
						radius: ['42%', '44%'],
						center: ['25%', '48%'],
						hoverAnimation: false,
						clockWise: true,
						itemStyle: {
							normal: {
								color: '#178ac9',
							},
						},
						tooltip: {
							show: false,
						},
						label: {
							show: false,
						},
						data: [1],
					}]
			
				};
				this.chart4.setOption(option, true);
				
				this.tootipTimer4 && this.tootipTimer4.clearLoop();
				this.tootipTimer4 = 0;
				this.tootipTimer4 = loopShowTooltip(this.chart4, option, {});
			},
			
			

		}
	}
</script>

<style scoped>
.page{background: #001029;display: flex;flex-direction: column;height: 100%;position:fixed;width: 100%;left: 0;top: 0;background:url(../assets/images/jy_01.jpg) no-repeat center;background-size:cover;}


.mod{margin-bottom: 30px;height: calc(33.3% - 20px);display: flex;flex-direction: column;}
.mod:last-child{bottom: 0;}
.mod .chart{height:calc(100% - 100px);flex:1;}

.mod1-list{display: grid;grid-template-columns: 1fr 1fr;grid-template-rows: 1fr 1fr 1fr;grid-gap: 30px;}
.mod1-list li{background-color: #08172E;display: flex;align-items: center;justify-content: space-between;padding:0 40px;font-size: 32px;color:#fff;}
.mod1-list .tit{font-size: inherit;}
.mod1-list .txt{font-size: inherit;}
.mod1-list .txt span{font-size: 40px;color:#45F5FF;font-family: 'simpds';padding-right: 8px;}
.mod1-list .txt i{font-family: 'simpds';}



.mod6-list{display: grid;grid-template-columns: 1fr 1fr;grid-template-rows: 1fr 1fr;grid-gap: 30px;}
.mod6-list .item{position:relative;overflow: hidden;}
.mod6-list .title{font-size: 30px;color:#fff;text-align: center;line-height: 34px;padding:15px 10px;position:absolute;width: 100%;left: 0;top: 0;background-color: #0a1f3e}

.cen-list{display: flex;justify-content: space-between;margin-bottom: 30px;flex-wrap: wrap;padding:0 7%;}
.cen-list li{text-align: center;width: 30%;margin-top: 20px;}
.cen-list .title{font-size: 44px;color:#fff;font-family: 'RuiZiZhenYan';height:70px;line-height: 70px;background:url(../assets/images/jt_01.png) no-repeat center;background-size:100% 100%;}
.cen-list .title em{font-size: 30px;font-family: 'RuiZiZhenYan';}
.cen-list .txt{font-size: 52px;color:#45F5FF;font-family: 'PangMenZhengDao';margin-top: 10px;}

.cen-map{}
.cen-map .point{position:absolute;}
.cen-map .point .tag{font-size: 26px;color:#fff;display: inline-block;vertical-align: middle;padding:0 30px;border-radius:8px;line-height: 55px;border:1px solid #45F5FF;background-color: #082658;}
.cen-map .point .icon{width: 100px;display: block;margin:0 auto;margin-top: 20px;}

.cen-map .point1{left: 20%;top: 50%;}
.cen-map .point2{left: 30%;top: 40%;}
.cen-map .point3{left: 35%;top: 60%;}
.cen-map .point4{left: 20%;top: 20%;}

.cen-map .item{width: 630px;border-radius:8px;background-color: rgba(8,52,120,.7);padding: 30px;position:absolute;z-index:50;}
.cen-map .item .top{display: flex;align-items: center;}
.cen-map .item .hdpic{border:3px solid #0051B5;border-radius:50%;width: 96px;height: 96px;margin-right: 25px;}
.cen-map .item .info{flex:1;overflow: hidden;}
.cen-map .item .name{font-size: 28px;color:#fff;}
.cen-map .item .tag{display: inline-block;vertical-align: middle;padding:0 15px;font-size: 24px;color:#fff;border-radius:21px;line-height: 36px;height: 36px;background-color: #0051b5;margin-left: 15px;}
.cen-map .item .txt{font-size: 22px;color:#fff;line-height: 1.5;margin-top: 20px;background-color: #082658;padding:20px;}
.cen-map .item .pics{margin-left: -2%;margin-top: 20px;display: flex;}
.cen-map .item .pic{width: 31.3%;margin-left: 2%;}
.cen-map .item .dz{font-size: 24px;color:#fff;margin-top: 20px;}
.cen-map .item .tel{font-size: 24px;color:#fff;margin-top: 8px;}

.cen-map .item1{left: 50%;top: 20%;}

</style>
